import * as React from 'react';
import '../styles/index.css';

export const footerHeight = 350;
export const overlap = 150;

const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: 'linear-gradient(180deg, transparent 0%, black 35%)',
        position: 'absolute',
        marginTop: -1 * overlap,
        width: '100vw',
        minHeight: footerHeight,
        textAlign: 'center',
        pointerEvents: 'none',
        fontFamily: 'Piedra'
      }}
    >
      <div style={{ height: overlap * 0.9, width: '100%' }}></div>
      <div style={{ pointerEvents: 'all' }}>
        <p>We Got Maggots</p>
        <p>Made with 💚🤘 in the Black Forest</p>
      </div>
    </div>
  );
};

export default Footer;
