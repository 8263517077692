import * as React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

// Wraps every page in a component
const wrapPageElement = (propsOrig: any) => {
  const { children } = propsOrig;
  const { element } = propsOrig;
  const { props } = propsOrig;
  return (
    <div {...props}>
      <Navigation />
      <div style={{ minHeight: 1000, width: '100vw' }}>
        {element}
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default wrapPageElement;
