import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import '../styles/index.css';

export const ItemStyle = { display: 'inline', marginLeft: 10, marginRight: 10, fontFamily: 'Piedra' };

export const pages: { [key: string]: string } = {
  HOME: '/',
  // BAND: '/band',
  IMPRINT: '/imprint'
};

const Navigation = (props: { }) => {
  const [toggle, setToggle] = React.useState(false);
  const animatedMenuWrapperStyle = useSpring<React.CSSProperties>({
    color: '#436e7a',
    marginBottom: 50
  });
  const animatedLogoStyle = useSpring<React.CSSProperties>({ maxHeight: (toggle ? 10 : 25) + 'vh' });

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setToggle(winScroll > 100 ? true : false);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <animated.div style={{ pointerEvents: 'none' }}>
      <animated.div
        style={{
          width: 'calc(100vw - 100px)',
          paddingLeft: 50,
          paddingRight: 50,
          backgroundImage: `linear-gradient(180deg, black 50%, transparent ${100}%)`,
          position: 'fixed',
          top: 0,
          ...animatedMenuWrapperStyle,
          fontSize: '1.6em'
        }}
      >
        <div style={{ pointerEvents: 'all', width: '100%', textAlign: 'center', marginTop: 25 }}>
          <animated.img
            onClick={() => (window.location.href = '/')}
            src="/Logo.webp"
            style={{ cursor: 'pointer', maxWidth: '75%', ...animatedLogoStyle }}
          />
          <animated.div
            style={{
              flex: 1,
              flexDirection: 'row',

              ...animatedMenuWrapperStyle,
              fontSize: '1.6em'
            }}
          >
            {Object.keys(pages).map((pageKey) => (
              <a style={ItemStyle} href={pages[pageKey]}>
                {pageKey}
              </a>
            ))}
          </animated.div>
        </div>
      </animated.div>
    </animated.div>
  );
};

export default Navigation;
